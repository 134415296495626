import env from "../providers/MoralisDappProvider/environment"

const { SECRET_KEY, KEY, CLIENT_ID } = env

export async function getUtilities(projectName) {
    const nodesUser = "admin"
    const nodesPass = "66THMQt&ld7KyF!3gUN"
    const credentials = Buffer.from(`${nodesUser}:${nodesPass}`).toString("base64")

    const res = await fetch("https://realtokerp.bacg.cloud:5000/api/v1.0/nodes/", {
      headers: {
        "Authorization": `Basic ${credentials}`,
      },
    })
    const nodes = await res.json()

    // Load the node that matches the project name
    const node = nodes.find(({ name }) =>
      projectName.includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName) ||
      projectName.replace(/ /g, "").includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName.replace(/ /g, ""))
    )
    if (!node) {
      throw new Error("No se encontró el proyecto asociado a la colección seleccionada, por favorp pruebe con otra colección.")
    }
    // Load utilities from the node's endpoint
    const endpoint = node.dns.slice(0, -1) + ":5000/" // add port
    const res2 = await fetch(endpoint + `utility?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}`)
    const utilities = await res2.json()
    
    return utilities
}