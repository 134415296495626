import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNFTTransfers } from "../../hooks/useNFTTransfers";
import { useHistory } from "react-router-dom";

import { useMoralis } from "react-moralis";
import {
  Card,
  Image,
  Tooltip,
  Modal,
  Input,
  Alert,
  Spin,
  Button,
  Badge,
} from "antd";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useNFTBalance } from "../../hooks/useNFTBalance";
import { useNFTCollectionUsers } from "../../hooks/useNFTCollectionUsers";
import { useUsersCollection } from "../../hooks/useUsersCollection";
import { useMoralisDapp } from "../../providers/MoralisDappProvider/MoralisDappProvider";
import { getExplorer } from "../../helpers/networks";
import { useWeb3ExecuteFunction } from "react-moralis";
import QRCode from "react-qr-code";
import { useMoralisQuery, useNewMoralisObject } from "react-moralis";
import { ethers, providers } from "ethers";
import env from "../../providers/MoralisDappProvider/environment"
import { useAccount } from "wagmi";
import { useUser } from "../../hooks/useUser";
import { getUtilities } from "../../helpers/utilities";
import { useProyectos } from "../../hooks/useProyectos"

const { Meta } = Card;
const { ENDPOINT, SECRET_KEY, KEY, CLIENT_ID } = env;

function CardProfile() {
  const { chainId, marketAddress, contractABI, walletAddress } = useMoralisDapp();
  const { NFTBalance, isLoading, nextPage, isLastPage } = useNFTBalance(walletAddress);

  const history = useHistory();

  // const minterAddress = "0x6c89C5C16F278E1114272A1A5EeB3644Da96C788";
  // const marketAddress = "0x837e63D23d0016D9fA7AD19237E850E4D8917c76";
  const { Moralis } = useMoralis();
  const { address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const [visibleQR, setVisibilityQR] = useState(false);
  const [qrText, setQRText] = useState(null);
  const [visible, setVisibility] = useState(false);
  const [nftToSend, setNftToSend] = useState(null);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const contractProcessor = useWeb3ExecuteFunction();
  const contractABIJson = JSON.parse(contractABI);
  const { NFTTransfers } = useNFTTransfers(
    nftToSend?.token_address,
    nftToSend?.token_id
  );
  const ItemImage = Moralis.Object.extend("ItemImages");
  const { CocreatorsNFT, Collection } = useNFTCollectionUsers(
    nftToSend?.token_address,
    nftToSend?.token_id
  ); // get co-creators of the collection this NFT belongs to (if any)
  const { Users } = useUsersCollection(Collection);
  const { Proyectos } = useProyectos();

  const isUserCoCreator = CocreatorsNFT.includes(walletAddress.toLowerCase())

  const [visibleCancel, setVisibilityCancel] = useState(false);
  const [nftToCancel, setNftToCancel] = useState(null);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const queryMarketItems = useMoralisQuery("MarketItemCreated", query => query.limit(100000));
  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "objectId",
      "createdAt",
      "price",
      "nftContract",
      "uid",
      "state",
      "tokenId",
      "seller",
      "owner",
      "confirmed",
    ])
  );
  //const ethers = Moralis.Web3;

  async function fetchUtilities(id, address, nodeName) {
    //var url = 'https://lighthousetest.powerfy.cloud:5000/get_nft?client_id=23yxAT7QDSHr&secret_key=cb5064LRvJkvICip&api_key=mOAVSBejeqgsl1dTKF2sM6oJrS4xc1uV&code=' + address + '-' + id
    const nodesUser = "admin"
    const nodesPass = "66THMQt&ld7KyF!3gUN"
    const credentials = Buffer.from(`${nodesUser}:${nodesPass}`).toString("base64")
    //const nodeName = NFTBalance.project;
    console.log(nodeName)
    const res = await fetch("https://realtokerp.bacg.cloud:5000/api/v1.0/nodes/", {
      headers: {
        "Authorization": `Basic ${credentials}`,
      },
    })
    const nodes = await res.json()

    // Load the node that matches the project name
    const node = nodes.find(({ name }) =>
      nodeName.includes(name.toLowerCase()) ||
      name.toLowerCase().includes(nodeName) ||
      nodeName.replace(/ /g, "").includes(name.toLowerCase()) ||
      name.toLowerCase().includes(nodeName.replace(/ /g, ""))
    )
    if (!node) {
      throw new Error("No se encontró el proyecto asociado a la colección seleccionada, por favorp pruebe con otra colección.")
    }
    // Load utilities from the node's endpoint
    const endpoint = node.dns.slice(0, -1) + ":5000/" // add port

    let url = `${endpoint}/get_nft?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&code=${address}-${id}`

    let response = await fetch(url)
      .then(resp => resp.json())
      .catch(err => console.log(err))
    return response
  }

  const [utitlityPrice, setUtilityPrice] = useState(null);
  const [priceLoading, setPriceLoading] = useState(false);

  useEffect(() => {
    setPriceLoading(true)

    if (!nftToSend || Users.length === 0) return

    fetchUtilities(nftToSend.token_id, nftToSend.token_address, nftToSend.project)
      .then(async (res) => {
        try {
          // Get utilities of the project
          const projectName = Users[0].get("username").toLowerCase()
          const utilidades = await getUtilities(projectName)
          const utils = res[0]
          let price = 0
          Object.entries(utils).forEach(([code, info]) => {
            if (utilidades.some(util => util.is_membership && util.code === code)) {
              const usesLeft = info.quantity - info.used_quantity
              const _price = info.price_unit
              price = _price
            }
          })
          
          setUtilityPrice(price)
          
          Users.forEach(user => {
            if(user.get("isProject"))
            setCreators(user.get("ethAddress"), price)
          })
        } catch(err) {
          alert("Error al cargar las utilidades del proyecto.:", err.message)
        } finally {
          setPriceLoading(false)
        }
      })
  }, [nftToSend, Users])

  // filter NFTs that are on sale
  const getMarketItem = (nft) => {
    const result = fetchMarketItems?.find(
      (e) =>
        e.nftContract === nft?.token_address &&
        e.tokenId === nft?.token_id &&
        e.state === "0" // State (0, 1, 2) = (For sale, Sold, Cancelled)
      // e.confirmed === true
    );
    return result;
  };

  async function list(nft, listPrice) {
    setLoading(true);
    const p = listPrice * 10 ** 6;
    console.log(p);
    const cocreatorsNFT = [[user.get("ethAddress"), listPrice]];
    console.log(cocreatorsNFT);

    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(marketAddress, contractABI, signer);
    console.log('calling createMarketItem')
    const tx = await contract.createMarketItem(
      nft.token_address,
      Number(nft.token_id),
      cocreatorsNFT, 
      p,{
      gasLimit: null,
      gasPrice: null,
    });
    await tx.wait().then( response => {
      setLoading(false);
      setVisibility(false);
      console.log('transaction', response)
      addItemImage();
      console.log('transaction.status', response.status)
      toast.success("Tu NFT ha sido listado en el marketplace");
    })
    .catch((err) => {
        setLoading(false);
        toast.error("Ocurrio un error al listar tu NFT");
        console.log(err)
    })
  }

  // async function approveAll(nft) {
  //   setLoading(true);
  //   const ops = {
  //     contractAddress: nft.token_address,
  //     functionName: "setApprovalForAll",
  //     abi: [
  //       {
  //         inputs: [
  //           { internalType: "address", name: "operator", type: "address" },
  //           { internalType: "bool", name: "approved", type: "bool" },
  //         ],
  //         name: "setApprovalForAll",
  //         outputs: [],
  //         stateMutability: "nonpayable",
  //         type: "function",
  //       },
  //     ],
  //     params: {
  //       operator: marketAddress,
  //       approved: true,
  //     },
  //   };

  //   await contractProcessor.fetch({
  //     params: ops,
  //     onSuccess: () => {
  //       console.log("Approval Received");
  //       setLoading(false);
  //       toast.success(
  //         "La aprobacion se ha realizado correctamente, puedes listar tu NFT"
  //       );
  //     },
  //     onError: (error) => {
  //       setLoading(false);
  //       toast.error("Ocurrio un error al aprobar tu NFT, intenta de nuevo");
  //     },
  //   });
  // }

  async function approveAll(nft) {
    setLoading(true);

    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      nft.token_address,
      [
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      signer
    );
 
    const tx = await contract.setApprovalForAll(marketAddress, true , 
      { gasLimit: null, gasPrice: null}
    );

    tx.wait()
      .then((response) => {
        setLoading(false);
        toast.success("La aprobacion se ha realizado correctamente, puedes listar tu NFT");
        
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          "Ocurrio un error al aprobar tu NFT, intenta de nuevo"
        );
      },
    );
  }

  const handleSellClick = (nft) => {
    setNftToSend(nft);
    setVisibility(true);
  };

  const handleCancelClick = (nft) => {
    setNftToCancel(nft);
    setVisibilityCancel(true);
  };

  // async function cancel() {
  //   setLoadingCancel(true);
  //   const tokenDetails = getMarketItem(nftToCancel);
  //   const itemID = tokenDetails.uid;
  //   const ops = {
  //     contractAddress: marketAddress,
  //     functionName: "deleteMarketItem",
  //     abi: contractABIJson,
  //     params: {
  //       itemId: itemID,
  //     },
  //   };

  //   await contractProcessor.fetch({
  //     params: ops,
  //     onSuccess: () => {
  //       console.log("success");
  //       setLoadingCancel(false);
  //       setVisibilityCancel(false);
  //       updateCancelMarketItem();
  //       toast.success("Tu NFT ha sido cancelado");
  //     },
  //     onError: (error) => {
  //       setLoadingCancel(false);
  //       toast.error("Ocurrio un error al cancelar tu NFT");
  //       console.log(error);
  //     },
  //   });
  // }

  async function cancel(){
    setLoadingCancel(true);
    const tokenDetails = getMarketItem(nftToCancel);
    const itemID = tokenDetails.uid;

    const provider = new providers.Web3Provider(window.ethereum);   
    const signer = provider.getSigner();
    const contract = new ethers.Contract(marketAddress, contractABI, signer);
      
    const tx = await contract.deleteMarketItem(itemID, {
      gasLimit: null,
      gasPrice: null,
    });

    tx.wait()
      .then((response) => {
        console.log("success");
        setLoadingCancel(false);
        setVisibilityCancel(false);
        updateCancelMarketItem();
        toast.success("Tu NFT ha sido cancelado");
      }).catch((error) => {
        setLoadingCancel(false);
        toast.error("Ocurrio un error al cancelar tu NFT");
        console.log(error);
        },
      );
  }



  async function updateCancelMarketItem() {
    const id = getMarketItem(nftToCancel).objectId;
    const marketList = Moralis.Object.extend("MarketItemCreated");
    const query = new Moralis.Query(marketList);
    await query.get(id).then((obj) => {
      obj.set("state", "2");
      // obj.set("buyer", walletAddress);
      obj.save();
    });
  }

  function addItemImage() {
    const itemImage = new ItemImage();

    itemImage.set("image", nftToSend.image);
    itemImage.set("nftContract", nftToSend.token_address);
    itemImage.set("tokenId", nftToSend.token_id);
    itemImage.set("name", nftToSend.name);
    itemImage.save();
  }

  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [inputValue, setInputValue] = useState("explore");

  async function showQR(nft) {
    //TODO: get nftUID from API

    const id = nft.token_address + "-" + nft.token_id;
    if (!id) {
      id = "123456";
    } //
    var projectName = nft.metadata.attributes[0].value;
    if (nft.metadata.attributes[0].trait_type === "Project"){
      projectName = nft.metadata.attributes[0].value;
    }else{
      projectName = "lighthouse cowork";
    }

    const nodesUser = "admin"
    const nodesPass = "66THMQt&ld7KyF!3gUN"
    const credentials = Buffer.from(`${nodesUser}:${nodesPass}`).toString("base64")

    const res = await fetch("https://realtokerp.bacg.cloud:5000/api/v1.0/nodes/", {
      headers: {
        "Authorization": `Basic ${credentials}`,
      },
    })
    const nodes = await res.json()

    // Load the node that matches the project name
    const node = nodes.find(({ name }) =>
      projectName.includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName) ||
      projectName.replace(/ /g, "").includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName.replace(/ /g, ""))
    )
    if (!node) {
      throw new Error("No se encontró el proyecto asociado a la colección seleccionada, por favorp pruebe con otra colección.")
    }
    // Load utilities from the node's endpoint
    const endpoint2 = node.dns.slice(0, -1) + ":5000/" // add port

    //var url = `https://lighthousetest.powerfy.cloud:5000/get_nft?client_id\=23yxAT7QDSHr&secret_key\=cb5064LRvJkvICip&api_key\=mOAVSBejeqgsl1dTKF2sM6oJrS4xc1uV&code\=${id}`;
    let url = `${endpoint2}/get_nft?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&code=${id}`
    const response = await fetch(url).then((res) => {
      return res.json();
    });
    setQRText(response[0]?.nftUID);
    setVisibilityQR(true);
  }
  //console.log("this is fetch market items", fetchMarketItems);

  function format(price) {
    price = String(price);
    return price.split(".").length === 2
      ? price.split(".")[0] + "." + price.split(".")[1].substr(0, 4)
      : price;
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [totalPrice, setTotalPrice] = useState(0);
  const [creators, _setCreators] = useState([]);

  function setCreators(addr, price) {
    const newCreators = [...creators];
    const result = creators.find(
      ({ address }) => address.toLowerCase() === addr.toLowerCase()
    );
    if (result) {
      result.price = price;
    } else {
      newCreators.push({ address: addr, price: price });
    }
    const sumPrices = newCreators.reduce((a, b) => a + b.price, 0);
    setTotalPrice(sumPrices);
    _setCreators(newCreators);
  }

  function handleConfirm() {
    if (totalPrice > 0) {
      list();
    } else {
      toast.error("Price must be greater than 0");
    }
  }

  async function list() {
    var listPrice = totalPrice * 10 ** 6;

    let tokenId = parseInt(nftToSend?.token_id);
    var tokenAddress = nftToSend?.token_address;
    var cocreatorsAndShares = [];
    for (let i = 0; i < creators.length; i++) {
      var creator = creators[i].address;
      var share = parseInt(creators[i].price * 10 ** 18);
      var elem = [creator, share.toString()];
      if (creator !== "" && share !== "" && share > 0) {
        cocreatorsAndShares.push(elem);
      }
    }

    setLoading(true);

    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(marketAddress, contractABI, signer);

    const tx = await contract.createMarketItem(
      tokenAddress,
      tokenId,
      cocreatorsAndShares,
      listPrice,
      { gasLimit: null, gasPrice: null }
    );

    tx.wait()
      .then((_) => {
        toast.success("Tu NFT ha sido listado en el marketplace");
        setLoading(false);
        setVisibility(false);
      })
      .catch((_) => {
        toast.error("Ocurrio un error al listar tu NFT, intenta de nuevo");
        setLoading(false);
        setVisibility(false);
      });
  }


  const [project, setProject] = useState(false)

  useEffect(() => {
    function isProject(address, project) {
      const getProject = Proyectos.find(e =>
        e.attributes.accounts[0].toLowerCase() === address.toLowerCase()
      )
      if (getProject !== undefined) {
        setProject(true)
        return true
      }
      return false
    }

    return () => { }
  }, [Proyectos, project])




  // Infinite scroll:
  // This callback will be called every time the referenced element gets created, with the element itself as an argument.
  // It will be attached to the last card.
  const observerRef = useRef(null)
  const lastCardRef = useCallback((card) => {
    if (isLastPage) return
    if (observerRef.current) observerRef.current.disconnect()
    observerRef.current = new IntersectionObserver(entries => {
        // we are just monitoring one element (the last card)
        if (entries[0].isIntersecting && !isLastPage) nextPage()
    })
    if (card) observerRef.current.observe(card)
  }, [NFTBalance])

  return (
    <div className="row mb-30_reset">
      {NFTBalance.map((nft, index) => (
        <div ref={NFTBalance.length === index+1 ? lastCardRef : null} className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={index}>
          <div className="card__item three">
            <div className="card_body space-y-10">
              <div className="card_head">
              <Link to={`/item-details/${nft.token_address}/${nft.token_id}/${nft.project}`}>
                    {nft.animation_url ? (
                      <video
                      //autoPlay
                      controls
                      loop
                      //poster="nft?.animation_url"
                      //preload="metadata"
                      type="video/mp4"
                      className="item_img"
                      src={nft?.animation_url + "#t=0.01"}
                      alt="ImgPreview"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "10px",
                        marginBottom: "15px",
                        position: "relative",
                        top: "-20px"
                      }}
                      />
                    ) : (
                      <img
                        className="item_img"
                        src={nft?.image ? nft?.image : "/img/bg/no-img.png"}
                      />
                    )}
                  </Link>
                {/* {nft?.metadata?.superutilities?.length > 0 && ( */}
                <div className="qr space-x-3">
                  <i
                    className="ri-qr-code-line ri-xl"
                    onClick={() => {
                      showQR(nft);
                    }}
                  />
                </div>
                {getMarketItem(nft) ? (
                  <div className="action">
                    <button
                      className="btn btn-sm btn-primary btn_auction"
                      onClick={() => handleCancelClick(nft)}
                    >
                      <i className="ri-auction-line color_white mr-5px" />
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <div className="action">
                    <button
                      className="btn btn-sm btn-primary btn_auction"
                      onClick={() => handleSellClick(nft)}
                    >
                      <i className="ri-auction-line color_white mr-5px" />
                      Vender
                    </button>
                  </div>
                )}
              </div>
              {/* =============== */}
              <div className="card_bottom">
                <h6 className="card_title">
                  <Link
                    className="color_black"
                    to={`/item-details/${nft.token_address}/${nft.token_id}/${nft.project}`}
                  >
                    {nft?.metadata?.name
                      ? nft?.metadata?.name.length > 25
                        ? nft.metadata.name.substring(0, 25) + "..."
                        : nft.metadata.name
                      : nft.symbol}
                  </Link>
                </h6>
                <div className="card_footer d-block space-y-10">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div className="creators space-x-10">
                      <Link to="profile">
                        <p className="avatars_name txt_sm">
                          {nft?.token_id?.length > 7
                            ? "#" + nft.token_id.substring(0, 7) + "..."
                            : "#" + nft.token_id}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="hr" />
                  <div
                    className="d-flex align-items-center space-x-10"
                    onClick={() =>
                      window.open(
                        `${getExplorer(chainId)}address/${nft.token_address}`,
                        "_blank"
                      )
                    }
                  >
                    <i className="ri-vip-crown-line" />
                    <p className="avatars_name txt_sm">View on explorer</p>
                    <p className="avatars_name txt_sm ">x{nft.amount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer position="bottom-right" />
        </div>
      ))}

      {isLoading ?
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img src="img/loader.gif" alt="" />
        </div>
      : (NFTBalance.length === 0 &&
          <h1>No hay resultados</h1>
        )
      }

      { isUserCoCreator ?
        <Modal
          title={`Listar ${
            nftToSend?.metadata?.name || nftToSend?.name
          } #${nftToSend?.token_id.substring(0, 20)}`}
          visible={visible}
          onCancel={() => setVisibility(false)}
          okText="Ok"
          footer={null}
        >
          <div className="mt-10">
            <Spin spinning={loadingCancel}>
              {/* tip={ !waitingConf ? "Subiendo Metadata a IPFS..." : "Esperando confirmacion de Wallet"  } spinning={loading}> */}
              <div
                style={{
                  width: "250px",
                  margin: "auto",
                }}
              ></div>
              <div className="space-y-10">
                {Users?.map((val, index) => (
                  <div className="row">
                    <div className="col-xl-6 col-sm-5">
                      <div className="creator_item creator_card space-x-10 ">
                        <div className="avatars space-x-10">
                          <Link
                            to={`/user/${val?.get("ethAddress")}`}
                            target="_blank"
                          >
                            <img
                              src={
                                val?.get("profilePic")
                                  ? val?.get("profilePic").url()
                                  : `/img/avatars/avatar_1.png`
                              }
                              alt="Avatar"
                              className="avatar avatar-sm"
                            />
                          </Link>
                          <Link
                            to={`/user/${val?.get("ethAddress")}`}
                            target="_blank"
                          >
                            <p className="avatars_name color_black">
                              {val?.get("username").length > 17
                                ? val?.get("username").substr(0, 17) + "..."
                                : val?.get("username")}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-5">
                    {val?.get("isProject") ? (
                        <Input
                          className="width_4"
                          style={{ color: "white" }}
                          type="number"
                          autoFocus
                          placeholder="Project price"
                          value={utitlityPrice || ""}
                          disabled={!project}
                        />
                      ) : (
                        <Input
                          className="width_4"
                          type="number"
                          autoFocus
                          min={0}
                          placeholder="price"
                          onChange={(e) => {
                            setCreators(
                              val?.get("ethAddress"),
                              Number(e.target.value)
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-20">
                {/* <p className="txt_lg">Subtotal: {totalPrice * 0.975} usdc</p> */}
                <p className="txt_lg">Total: {totalPrice} usdc</p>
                <p className="txt_md">2.5% is distributed to DAO</p>
              </div>
              <Button
                onClick={() => handleConfirm()}
                type="primary"
                className="mt-20 btn_lg btn_primary"
                disabled={priceLoading}
                style={{
                  height: "40px",
                  width: "100%",
                  borderRadius: "15px",
                }}
              >
                {priceLoading ? "Cargando..." : "Confirmar"}
              </Button>
            </Spin>
          </div>
        </Modal>
        :
        <Modal
          title={`Listar ${
            nftToSend?.metadata?.name || nftToSend?.name
          } #${nftToSend?.token_id.substring(0, 20)}`}
          visible={visible}
          onCancel={() => setVisibility(false)}
          okText="List"
          footer={[
            <Button onClick={() => approveAll(nftToSend)} type="primary">
              Approve
            </Button>,
            <Button onClick={() => list(nftToSend, price)} type="primary">
              List
            </Button>,
          ]}
        >
          <Spin spinning={loadingCancel}>
            <div
              style={{
                width: "250px",
                margin: "auto",
              }}
            >
              <Badge.Ribbon color="green" text={`${price}`}>

              {nftToSend?.animation_url ? (
                    <video
                    //autoPlay
                    controls
                    //poster="nft?.animation_url"
                    //preload="metadata"
                    type="video/mp4"
                    className="item_img"
                    src={nftToSend?.animation_url + "#t=2"}
                    alt="ImgPreview"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                    />
                  ) : (
                    <img
                      className="item_img"
                      src={nftToSend?.image ? nftToSend?.image : "/img/bg/no-img.png"}
                      style={{
                        width: "250px",
                        borderRadius: "10px",
                        marginBottom: "15px",
                      }}
                    />
                  )}




              </Badge.Ribbon>
            </div>
            <div className="space-y-10">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-sm-5">
                  <Input
                    className="width_4"
                    type="number"
                    autoFocus
                    placeholder="Creator price"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-20">
              <p className="txt_lg">Total: {format(price)} usd</p>
              <p className="txt_md">2.5% is distributed to DAO</p>
            </div>
          </Spin>
        </Modal>
      }

      <Modal
        title={`Cancel ${nftToCancel?.name} #${nftToCancel?.token_id}`}
        visible={visibleCancel}
        onCancel={() => setVisibilityCancel(false)}
        onOk={() => cancel()}
        okText="Cancel NFT"
        footer={[
          <Button onClick={() => cancel()} type="primary">
            Cancel
          </Button>,
        ]}
      >
        <Spin spinning={loadingCancel}>
          <div
            style={{
              width: "250px",
              margin: "auto",
            }}
          >
            <Badge.Ribbon
              color="green"
              text={`${getMarketItem(nftToCancel)?.price / (1000000)} USDC`}
            >

            {nftToCancel?.animation_url ? (
                  <video
                  //autoPlay
                  controls
                  //poster="nft?.animation_url"
                  //preload="metadata"
                  type="video/mp4"
                  className="item_img"
                  src={nftToCancel?.animation_url + "#t=2"}
                  alt="ImgPreview"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "15px",
                  }}
                  />
                ) : (
                  <img
                    className="item_img"
                    src={nftToCancel?.image ? nftToCancel?.image : "/img/bg/no-img.png"}
                    style={{
                      width: "250px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                )}


            </Badge.Ribbon>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={visibleQR}
        onCancel={() => setVisibilityQR(false)}
        footer={null}
        className="qr_modal"
      >
        <div
          style={{
            background: "white",
            height: "100%",
            padding: "60px",
          }}
        >
          {qrText && <QRCode value={qrText} size="200" />}
        </div>
      </Modal>
    </div>
  );
}

export default CardProfile;
