import React, { useRef, useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import Header from "../../../components/header/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMoralisFile } from "react-moralis";
import { useWeb3ExecuteFunction } from "react-moralis";
import { useAllUsers } from '../../../hooks/useAllUsers';
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import SearchProject from '../../../components/header/Menu/SearchProject';
import {
  Card,
  Image,
  Tooltip,
  Modal,
  Input,
  Alert,
  Spin,
  Button,
  message,
  AutoComplete,
  Cascader,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CopyOutlined } from "@ant-design/icons";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useNewMoralisObject } from "react-moralis";
import Web3 from "web3";
import { Redirect } from "react-router-dom";
import { useAccount } from "wagmi";
import { useUser } from "../../../hooks/useUser";

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

const CreateCollection = () => {
  useDocumentTitle(" Crear Coleccion");
  const forceUpdate = useForceUpdate();

  const { isConnected: isAuthenticated, address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const [cocreatorsUsernames, setCocreatorsUsernames] = useState([])
  const [project, setProject] = useState([])
  const [fileLogo, setfileLogo] = useState(null);
  const [fileImage, setfileImage] = useState(null);
  const [fileBanner, setFileBanner] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const { saveFile, moralisFile } = useMoralisFile();
  const [facebook, setFacebook] = useState("defaultfacebook");
  const [instagram, setInstagram] = useState("defaultinstagram");
  const [twitter, setTwitter] = useState("defaulttwitter");
  const [discord, setDiscord] = useState("defaultdiscord");
  const [visibleCreating, setVisibleCreating] = useState(false);
  const { Users } = useAllUsers();
  const { control, register, getValues } = useForm();
  const {
    fields: cocreators,
    append: appendCocreator,
    remove: removeCocreator,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "cocreators", // unique name for your Field Array
  });
  const { save } = useNewMoralisObject("Collection");

  function handleCreateClick() {
    if (getValues("cocreators").some((coCreateror) => coCreateror.address === ""))
      return message.error("Remueve o completa los campos de co-creadores");

    if (
      title != null &&
      description != null &&
      fileLogo != null &&
      fileImage != null &&
      fileBanner != null
    ) {
      setVisibleCreating(true);
      var socialLinks = {
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
        discord: discord,
      };

      // check valid adress and not repeated
      var creators = [];
      var list = getValues("cocreators");
      for (var i = 0; i < list.length; i++) {
        let addr = list[i].address;
        if (
          Web3.utils.isAddress(addr) &&
          addr.toLowerCase() !== user.get("ethAddress").toLowerCase() && addr !== project &&
          creators
            .map((e) => e.address.toLowerCase())
            .indexOf(addr.toLowerCase()) === -1
        ) {
          // check if address is not repeated
          creators.push({ address: addr.toLowerCase() });
        }
      }
      
      if( user.get("ethAddress") !== project ){
        creators.unshift({ address: project})
      }
      
      creators.push({ address: user.get("ethAddress") }); // add owner address
      //creators.push({ address: project }); // add owner address

      const data = {
        creatorAddress: user.get("ethAddress"),
        name: title,
        description: description,
        socialLinks: socialLinks,
        co_creators: creators,
      };

      // save to moralis
      saveFile("photo.jpg", fileLogo, {
        type: "image/png",
        onSuccess: (result) => {
          data.logo = result;
          saveFile("photo2.jpg", fileImage, {
            type: "image/png",
            onSuccess: (result) => {
              data.image = result;
              saveFile("photo3.jpg", fileBanner, {
                type: "image/png",
                onSuccess: (result) => {
                  data.banner = result;
                  save(data, {
                    onSuccess: (collection) => {
                      setVisibleCreating(false);
                      message.success("New Collection created");
                    },
                    onError: (error) => {
                      setVisibleCreating(false);
                      message.error("Failed to create Collection");
                      console.log(error);
                    },
                  });
                },
                onError: (error) => console.log(error),
              });
            },
            onError: (error) => console.log(error),
          });
        },
        onError: (error) => console.log(error),
      });
    } else {
      message.error("Por favor, completa todos los campos");
    }
  }

  const findName = (e, index) => {
    let toChangeCocreators = cocreatorsUsernames;

    if(e.target.value.length === 42 && index < cocreators.length){
      let username = Users.find((U) => U.attributes.ethAddress === e.target.value)
      if(username) {
       toChangeCocreators[index] = username.attributes.username;
        forceUpdate()
      }      
      else
        toChangeCocreators[index] = ''
      setCocreatorsUsernames(toChangeCocreators)
    }
    else{
      toChangeCocreators[index] = '';
      setCocreatorsUsernames(toChangeCocreators)
    }
  }

  if (isAuthenticated) {
    return (
      <div>
        <Header />
        <div className="hero__upload">
          <div className="container">
            <div className="space-y-20">
              <h1 className="title">Crea una coleccion</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="box in__upload mb-120">
            <div className="row justify-content-center">
              <div className="col-lg-7 space-y-30">
                <div>
                  <h4 className="title">Imagen de perfil</h4>
                  <p className="txt_sm mt-10">
                    Esta imagen se mostrara en la pagina de la coleccion. Se
                    recomienda 300 x 300
                  </p>
                  <div className="col-lg-2 mt-20 space-y-10">
                    <div className="row collection_logo__part space-y-40 upload_file">
                      {!fileLogo ? (
                        <Fragment>
                          <div id="boxUpload">
                            <div className="space-y-20">
                              <img
                                className="icon"
                                src={`img/icons/upload.svg`}
                                alt="upload"
                              />
                              <input
                                type="file"
                                onChange={(e) => setfileLogo(e.target.files[0])}
                              />
                            </div>
                          </div>
                          <ToastContainer />
                        </Fragment>
                      ) : (
                        <Fragment>
                          {fileLogo?.type == "video/mp4" ? (
                            <video autoPlay controls className="item_img ">
                              <source
                                src={URL.createObjectURL(fileLogo)}
                                type={fileLogo.type}
                              />
                 o             Your browser does not support HTML5 video.
                            </video>
                          ) : (
                            <img
                              src={URL.createObjectURL(fileLogo)}
                              className="item_img"
                            />
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="title">Imagen principal</h4>
                  <p className="txt_sm mt-10">
                    Esta imagen se utilizará para presentar su colección en la
                    página de inicio, las páginas de categoría u otras áreas
                    promocionales. Se recomienda 800 x 600
                  </p>
                  <div className="col-lg-5 mt-20">
                    <div className="row collection_left__part space-y-40 upload_file">
                      {!fileImage ? (
                        <Fragment>
                          <div id="boxUpload">
                            <div className="space-y-20">
                              <img
                                className="icon"
                                src={`img/icons/upload.svg`}
                                alt="upload"
                              />
                              <input
                                type="file"
                                onChange={(e) =>
                                  setfileImage(e.target.files[0])
                                }
                              />
                            </div>
                          </div>
                          <ToastContainer />
                        </Fragment>
                      ) : (
                        <Fragment>
                          {fileImage?.type == "video/mp4" ? (
                            <video autoPlay controls className="item_img ">
                              <source
                                src={URL.createObjectURL(fileImage)}
                                type={fileImage.type}
                              />
                              Your browser does not support HTML5 video.
                            </video>
                          ) : (
                            <img
                              src={URL.createObjectURL(fileImage)}
                              className="item_img"
                            />
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="title">Imagen Banner</h4>
                  <p className="txt_sm mt-10">
                    Esta imagen aparecerá en la parte superior de la página de
                    tu colección. Evite incluir demasiado texto en esta imagen
                    de banner, ya que las dimensiones cambian en diferentes
                    dispositivos. Se recomienda 1400 x 350
                  </p>
                  <div className="row mt-30 collection_left__part space-y-40 upload_file">
                    {!fileBanner ? (
                      <Fragment>
                        <div id="boxUpload">
                          <div className="space-y-20">
                            <img
                              className="icon"
                              src={`img/icons/upload.svg`}
                              alt="upload"
                            />
                            <input
                              type="file"
                              onChange={(e) => setFileBanner(e.target.files[0])}
                            />
                          </div>
                        </div>
                        <ToastContainer />
                      </Fragment>
                    ) : (
                      <Fragment>
                        {fileBanner?.type == "video/mp4" ? (
                          <video autoPlay controls className="item_img ">
                            <source
                              src={URL.createObjectURL(fileBanner)}
                              type={fileBanner.type}
                            />
                            Your browser does not support HTML5 video.
                          </video>
                        ) : (
                          <img
                            src={URL.createObjectURL(fileBanner)}
                            className="item_img"
                          />
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="space-y-20">
                    <div className="space-y-10">
                      <span className="nameInput">Nombre</span>
                      <input
                        type="text"
                        placeholder="e. g. `realtok design art`"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="space-y-10">
                      <span className="nameInput">Descripción</span>

                      <textarea
                        style={{ minHeight: 110 }}
                        placeholder="e. g. `realtok design art`"
                        onChange={(e) =>setDescription(e.target.value)}
                      />
                    </div>
                    <h3 className="mb-20">Social Links</h3>
                    <div className="form-group space-y-15">
                      <div className="d-flex flex-column">
                        <span className="nameInput mb-10">Instagram</span>
                        <input
                          type="text"
                          placeholder={"instagram username"}
                          onChange={(e) => setInstagram(e.target.value)}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <span className="nameInput mb-10">Twitter</span>
                        <input
                          type="text"
                          placeholder={"twitter username"}
                          onChange={(e) => setTwitter(e.target.value)}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <span className="nameInput mb-10">Facebook</span>
                        <input
                          type="text"
                          placeholder={"facebook username"}
                          onChange={(e) => setFacebook(e.target.value)}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <span className="nameInput mb-10">Discord</span>
                        <input
                          type="text"
                          placeholder={"discord username or invitation code"}
                          onChange={(e) => setDiscord(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-30">
                  <SearchProject fn={setProject}/>
                  <div className="mb-30"></div>
                  <span className="mb-10">Co-Creadores</span>
                  <p className="mb-20">
                    Los colaboradores pueden recibir pagos por los artículos que
                    han creado y crear nuevos artículos dentro de la coleccion.
                  </p>
                  <div className="space-y-10">
                    {cocreators.map((item, index) => (
                      <Row gutter={8} key={item.id}>
                        <Col span={22}>
                          <input
                            type="text"
                            placeholder="Address, por ejemplo: 0x4b...81Ca2"
                            onInput={ (e) => findName(e, index)}
                            {...register(`cocreators.${index}.address`)}
                          />
                          {<p>{cocreatorsUsernames[index]}</p>}
                        </Col>
                        {/* <Col span={5}>
                          <Controller 
                          render={({ field }) => 
                          <input type="number" max="10" min="0"  placeholder="e. g. 5%" {...field} />}
                          name={`cocreators.${index}.value`}
                          control={control}
                          />
                        </Col> */}
                        <Col span={2}>
                          <button
                            type="button"
                            className="btn btn-white btn-lg ri-close-line"
                            onClick={() => 
                              { 
                                let fixingArray = cocreatorsUsernames
                                fixingArray.splice(index, 1)
                                setCocreatorsUsernames(fixingArray)
                                removeCocreator(index)
                              }
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                    <button
                      type="button"
                      className="btn btn-dark btn-md mt-20 mb-20"
                      onClick={() =>
                        appendCocreator({ address: "", value: "" })
                      }
                    >
                      Agregar Co-Creador
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed_row bottom-0 left-0 right-0">
          <div className="container">
            <div className="row content justify-content-between mb-20_reset">
              <div className="col-md-auto col-12 mb-20">
                <div className="space-x-10">
                  <Link to="/upload-type" className="btn btn-dark others_btn">
                    Cancel
                  </Link>
                </div>
              </div>
              <div className="col-md-auto col-12 mb-20">
                <button
                  className="btn btn-primary btn_create"
                  onClick={() => handleCreateClick()}
                >
                  Crear
                </button>
              </div>
            </div>
            <Modal
              visible={visibleCreating}
              onCancel={() => setVisibleCreating(false)}
              footer={null}
              width={450}
            >
              <h4>Creating Collection</h4>
              <Spin tip={"Creating collection, wait"} spinning={true}></Spin>
            </Modal>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default CreateCollection;