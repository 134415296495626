import React, { useRef, useState, useEffect, Fragment } from 'react';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Countdown from 'react-countdown';
import { useMoralis, useMoralisQuery, useNewMoralisObject } from "react-moralis";
import { useWeb3ExecuteFunction } from "react-moralis";
import useDocumentTitle from '../../../components/useDocumentTitle';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useNFTDetails } from "../../../hooks/useNFTDetails";
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import { getExplorer } from "../../../helpers/networks";
import { Redirect } from 'react-router-dom'
import { useNFTTransfers } from '../../../hooks/useNFTTransfers';
import { useUsersTransfers } from '../../../hooks/useUsersTransfers';
import { useCollections } from '../../../hooks/useCollections';
import { useAllUsers } from '../../../hooks/useAllUsers';
import NftCoCreators from '../../../components/creators/NftCoCreators';
import { toast, ToastContainer } from 'react-toastify';
import { Card, Image, Tooltip, Modal, Input, Alert, Spin, Button, Badge } from "antd";
import { ethers, providers } from 'ethers'
import env from "../../../providers/MoralisDappProvider/environment"
import { useHistory } from "react-router-dom";


require('dotenv').config()


function ItemDetails() {
  const { ENDPOINT,SECRET_KEY,KEY,CLIENT_ID } = env;

  const utilidades = ['5', '10', '15', '20','22', '25', '30', '31','32', '11','70','75','80']
  const accesos = ['35', '40', '45', '50','51','70','75','80', '55', '100']
  const { address, id, projectNameNode } = useParams();
  var nodeName = "";
  if (projectNameNode ){
    nodeName = projectNameNode ;
  }else{
    nodeName = "lighthouse";
  }
  const { chainId,
    marketAddress,
    contractABI,
    walletAddress,
    usdcAddress } = useMoralisDapp();

  //const marketAddress = "0x837e63D23d0016D9fA7AD19237E850E4D8917c76"
  const { NFTDetails } = useNFTDetails(address, id);
  const { NFTTransfers } = useNFTTransfers(address, id);
  const [visible, setVisibility] = useState(false);
  const [nftToBuy, setNftToBuy] = useState(null);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [isShare, setShare] = useState(false);
  const [loadingApiHit, setLoadingApiHit] = useState(false)
  const [apiHitResponse, setApiHitResponse] = useState();
  const [apiHitResponseSuccess, setApiHitResponseSuccess] = useState(false);
  const [CPSuccess, setCPSuccess] = useState(false);
  const [CP, setCP] = useState();
  const { Collections } = useCollections(10000) // very high limit to get all collections
  const { Moralis } = useMoralis();
  const { Users } = useAllUsers();
  const contractABIJson = JSON.parse(contractABI);
  const contractProcessor = useWeb3ExecuteFunction();
  const history = useHistory();
  
  const queryMarketItems = useMoralisQuery("MarketItemCreated",
    query => query.limit(5)
      .equalTo("nftContract", address)
      .equalTo("tokenId", id)
  );

  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "objectId",
      "createdAt",
      "price",
      "nftContract",
      "uid",
      "state",
      "tokenId",
      "seller",
      "owner",
      "confirmed",
    ])
  );
  const toggleShare = () => {
    setShare(!isShare);
  };
  const [isMore, setMore] = useState(false);

  const toggleMore = () => {
    setMore(!isMore);
  };

  const CoCreators = Collections.find((col) => {
    let source_checksum = ethers.utils.getAddress(address) + "/" + id + "/0x89"
    let source_plain = address + "/" + id + "/0x89"
    let res = col.attributes.items?.includes(source_checksum) || col.attributes.items?.includes(source_plain)
    return res
  })

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  async function purchase() {
    await approveUSDC()
    setLoading(true);
    const tokenDetails = getMarketItem();

    const itemID = tokenDetails.uid;

    const tokenPrice = tokenDetails.price;
    let royal = [];

    if (NFTDetails.metadata && NFTTransfers) {
      if (NFTTransfers?.list?.length > 1){
        royal = NFTDetails.metadata.royalties.map((item) => { return [item.address, item.share] });
      }
    }


    const provider = new providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(marketAddress, contractABI, signer);

    const transaction = await contract.createMarketSale(
      address,
      itemID,
      tokenPrice,
      royal,
      {
        gasLimit: 1000000
        , gasPrice: null
      });
    transaction.wait()
      .then((resp) => {
        setLoading(false);
        setVisibility(true);
        updateSoldMarketItem(address, id);
        toast.success("Purchase Successful");
        history.push(`/profile`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Purchase Failed");
        console.log(err)
      })
  }

  async function approveUSDC() {
    setLoading(true);
    const tokenDetails = getMarketItem();
    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(usdcAddress, [
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
    ], signer);

    const tx = await contract.approve(marketAddress, tokenDetails.price,
      { gasLimit: null, gasPrice: null })

    tx.wait()
      .then((response) => {
        toast.success("La aprobacion se ha realizado correctamente");
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        toast.error(
          "Ocurrio un error al aprobar tu USDC, intenta de nuevo" + error.message
        );
      })
  }

  function formatDate(date) {
    var dateSplit = date.split('T')
    var fecha = dateSplit[0]
    var tiempo = dateSplit[1].split('.')[0]
    return fecha + " " + tiempo

  }

  async function fetchUtilities(id, address) {
    //var url = 'https://lighthousetest.powerfy.cloud:5000/get_nft?client_id=23yxAT7QDSHr&secret_key=cb5064LRvJkvICip&api_key=mOAVSBejeqgsl1dTKF2sM6oJrS4xc1uV&code=' + address + '-' + id
    const nodesUser = "admin"
    const nodesPass = "66THMQt&ld7KyF!3gUN"
    const credentials = Buffer.from(`${nodesUser}:${nodesPass}`).toString("base64")

    const res = await fetch("https://realtokerp.bacg.cloud:5000/api/v1.0/nodes/", {
      headers: {
        "Authorization": `Basic ${credentials}`,
      },
    })
    const nodes = await res.json()

    // Load the node that matches the project name
    const node = nodes.find(({ name }) =>
      nodeName.includes(name.toLowerCase()) ||
      name.toLowerCase().includes(nodeName) ||
      nodeName.replace(/ /g, "").includes(name.toLowerCase()) ||
      name.toLowerCase().includes(nodeName.replace(/ /g, ""))
    )
    if (!node) {
      throw new Error("No se encontró el proyecto asociado a la colección seleccionada, por favorp pruebe con otra colección.")
    }
    // Load utilities from the node's endpoint
    const endpoint = node.dns.slice(0, -1) + ":5000/" // add port

    let url = `${endpoint}/get_nft?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&code=${address}-${id}`
    setLoadingApiHit(true)
    let response = await fetch(url)
      .then(resp => resp.json())
      .catch(err => console.log(err))
    setLoadingApiHit(false)
    return response
  }

  function matchNumberToUtilityName(number) {
    switch (number) {
      case '5':
        return 'Membresía Viajero'
      case '10':
        return 'Membresía Flexible'
      case '11':
        return 'Membresía Flexible Quincenal'
      case '15':
        return 'Membresía Premium'
      case '20':
        return 'Eventos'
      case '25':
        return 'Salas Especiales'
      case '30':
        return 'Promocional'
      case '32':
        return 'Day Pass'
      case '35':
        return 'Escritorio Variable'
      case '40':
        return 'Escritorio Fijo'
      case '45':
        return 'Sala de reuniones'
      case '50':
        return 'Sala de streaming'
      case '51':
        return 'Sala Privada'
      case '55':
        return 'Sala de eventos'
      case '70':
        return 'Sala Privada por hora'
      case '75':
        return 'Sala Privada por dia'
      case '80':
        return 'Sala Privada por mes'
      case '100':
        return 'Sala Privada'
      default: return;
    }
  }

  function matchNumberToUnit(number) {
    switch (number) {
      case '5':
        return 'unidades'
      case '10':
        return 'unidades'
      case '11':
        return 'días'
      case '15':
        return 'unidades'
      case '20':
        return 'unidades'
      case '25':
        return 'unidades'
      case '30':
        return 'unidades'
      case '35':
        return 'días'
      case '40':
        return 'días'
      case '45':
        return 'Horas'
      case '50':
        return 'Horas'
      case '51':
        return 'Horas'
      case '55':
        return 'Horas'
      case '70':
        return 'Horas'
      case '75':
        return 'días'
      case '80':
        return 'mes'
      case '100':
        return 'unidades'
      default: return;
    }

  }
  function matchEvent(fromAddress){
    switch (fromAddress) {
      case '0x0000000000000000000000000000000000000000':
        return 'Creado por'
      default:
        return 'Transferido de'

    }
  }

  async function updateSoldMarketItem(address, tokenid) {
    const id = getMarketItem().objectId;
    const marketList = Moralis.Object.extend("MarketItemCreated");
    const query = new Moralis.Query(marketList);
    await query.get(id).then((obj) => {
      obj.set("state", "1");
      obj.set("buyer", walletAddress);
      obj.save();
    });

    //var url = 'http://lighthousetest.powerfy.cloud:5000/update_nft?client_id=23yxAT7QDSHr&secret_key=cb5064LRvJkvICip&api_key=mOAVSBejeqgsl1dTKF2sM6oJrS4xc1uV&code=' + address + '-' + id

    let url = `${ENDPOINT}/update_nft?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&code=${address}-${tokenid}`

    var config = {
      method: 'PUT',
      //mode: 'cors',
      //headers: new Headers({'Access-Control-Allow-Origin': '*'}),
      body: JSON.stringify({
        "sold": true
      })
    }
    fetch(url, config)
  }

  // filter NFTs that are on sale
  const getMarketItem = () => {
    const result = fetchMarketItems?.find(
      (e) =>
        e.nftContract === address &&
        e.tokenId === id &&
        e.state === "0" //&&     // State (0, 1, 2) = (For sale, Sold, Cancelled)
        //e.confirmed === true
    );
    return result;
  };

  function getRoyalties(nft) {
    var roy = null;
    let arr = nft.metadata?.royalties;
    if (arr) {
      for (let share of arr) {
        roy += share.share / 100;
      }
    }
    return roy;
  }

  useEffect(() => {
    if (apiHitResponseSuccess) return;
    fetchUtilities(id, address)//pass add and id
      .then(function (response) {
        // if response is not null then set the state
        if (response) {
          console.log(response[0]);
          console.log("aca matu");
          setApiHitResponse(response[0])
          setApiHitResponseSuccess(true)
        } else {
          setApiHitResponseSuccess(false)
        }
      })
  }, [apiHitResponseSuccess])

  const getProfilePic = (address) => {
    const found = Users.find(e => e.attributes.ethAddress === address);
    if (found && found.attributes.profilePic)
      return found.attributes.profilePic._url;
    return "/img/avatars/avatar_2.png"
  }

  const getProfileUsername = (address) => {
    const found = Users.find(e => e.attributes.ethAddress === address);
    if (found)
      return found.attributes.username;
    return address
  }

  useDocumentTitle('Ver NFT ');

  function formatAddress(address) {
    //console.log(address)
    let first = address?.substr(0, 4)
    let second = address?.substr(address.length - 4)

    return `${first}..${second}`
  }

  function renderComponent() {
    //MWEPFER terminar logica para determinar si soy el dueño y lo tengo a la venta si quiero cancelar
    // o si soy el dueño y no lo tengo a la venta y quiero ponerlo a un determinado precio
    // pero ahi tengo que evaluar si soy el primer creador o si soy un vendedor posterior 
    // si soy el primero tengo que abrir el listado de cocreadores y poner varios precios
    // si soy el segundo vendedor poner un solo precio de venta
    if (NFTTransfers.length> 0){
      if (NFTTransfers.list[0].to_address === walletAddress){
        return <button className="btn btn-primary btn-lg btn-block" onClick={() => setVisibility(true)}>Cancelar Venta</button>
      } else {
        return <button className="btn btn-primary btn-lg btn-block" onClick={() => setVisibility(true)}>Comprar2</button>
      }
    } else {
      if (NFTTransfers.creator === walletAddress){
        return <button className="btn btn-primary btn-lg btn-block" onClick={() => setVisibility(true)}>Cancelar Venta</button>
      } else {
        return <button className="btn btn-primary btn-lg btn-block" onClick={() => setVisibility(true)}>Comprar2</button>
      }
    }
  }

  if (NFTDetails && NFTTransfers) {
  
    return (
      <div>
        <Header />
        <div className="container my-45">
          <div className="item_details">
            <div className="row sm:space-y-20">
              <div className="col-lg-6 d-flex justify-content-top flex-column">
                {/* style={{maxHeight : "1000px"}} */}
                {NFTDetails.animation_url ? (
                  <video
                    //autoPlay
                    controls
                    loop
                    type="video/mp4"
                    className="item_img"
                    src={NFTDetails.animation_url + "#t=0.01"}
                    alt="ImgPreview"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "15px",
                      position: "relative",
                      maxHeight : "100%",
                      top: "0px"
                    }}
                />
                ) : (
                  <img
                    className="item_img"
                    src={NFTDetails?.image ? NFTDetails.image : "/img/bg/no-img.png"}
                    alt="ImagePreview"
                  />
                )}
              </div>

              <div className="col-lg-6 d-flex justify-content-top flex-column">



                <div className="space-y-10 mt-10">
                  <div className="ml-10">
                    {(CoCreators) && (<Link to={`/collection/${CoCreators.attributes.name.replace(/ /g, "-").toLowerCase()}`}> Colección: {CoCreators.attributes.name}</Link>)}
                    <div className="mt-10" style={{"line-height": "1.5;"}}>
                      {NFTDetails?.metadata?.name ? (
                        <h3 style={{ "line-height": "1.5"}}>
                              {NFTDetails.metadata.name}</h3>
                      ) : (
                        <h3 style={{ "line-height": "1.5"}}>{NFTDetails.name}</h3>
                      )}
                    </div>
                  </div>


                  <div className="avatars space-x-10 ml-10">
                      <h8>Creador por : &nbsp;
                        <Link to={`/user/${NFTTransfers?.creator}`}>
                          <img
                            src={getProfilePic(NFTTransfers.creator)}
                            alt="Avatar"
                            className="avatar avatar-sm"
                          />
                        </Link>
                        &nbsp;
                        <Link 
                          className="color_black txt _bold"
                          to={`/user/${NFTTransfers?.creator}`}>
                            {getProfileUsername(NFTTransfers?.creator)}
                        </Link>
                      </h8>
                  </div>


                  {(NFTTransfers?.list?.length > 0) && (
                    <div className="avatars space-x-10 ml-10">
                      <h8>Propiedad de: &nbsp;
                        <Link to={`/user/${NFTTransfers.list[0].to_address}`}>
                          <img
                            src= {getProfilePic(NFTTransfers.list[0].to_address)}
                            alt="Avatar"
                            className="avatar avatar-sm"
                          />
                        </Link>
                        &nbsp;
                        <Link
                          className="color_black txt _bold"
                          to={`/user/${NFTTransfers.list[0].to_address}`}>
                          {getProfileUsername(NFTTransfers.list[0].to_address)}
                        </Link>
                      </h8>
                    </div>
                  )}

                  {getMarketItem() && (
                    <Fragment>
                      <div className="numbers">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="space-y-5 ml-10">
                              <p className="color_text">Precio Actual</p>
                              <h4 className='color_brand'>
                                {getMarketItem()?.price / ("1e" + 6)} USDC
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* MWEPFER Render boton comprar segun logica nueva renderComponent()*/}
                      <button className="btn btn-primary btn-lg btn-block" onClick={() => setVisibility(true)}>Comprar</button>
                    </Fragment>
                  )}


                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <div className="space-x-10 d-flex align-items-center">
                        {/* <p>1 of 1</p> */}
                      </div>
                      <NftCoCreators Collection={CoCreators}></NftCoCreators>
                    </div>
                    <div className="space-x-10 d-flex align-items-center">
                      <div>
                        <div className="share">
                          <div className="icon" onClick={toggleShare}>
                            <i className="ri-share-line"></i>
                          </div>
                          <div
                            className={`dropdown__popup ${isShare ? 'visible' : null
                              } `}>
                            <ul className="space-y-10">
                              <li>
                                <a href="https://www.facebook.com/" rel="noreferrer" target="_blank">
                                  <i className="ri-facebook-line"></i>
                                </a>
                              </li>
                              <li>
                                <a href="https://www.messenger.com/" rel="noreferrer" target="_blank">
                                  <i className="ri-messenger-line"></i>
                                </a>
                              </li>
                              <li>
                                <a href="https://whatsapp.com" target="_blank" rel="noreferrer" >
                                  <i className="ri-whatsapp-line"></i>
                                </a>
                              </li>
                              <li>
                                <a href="https://youtube.com" target="_blank" rel="noreferrer" >
                                  <i className="ri-youtube-line"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="more">
                          <div className="icon" onClick={toggleMore}>
                            <i className="ri-more-fill"></i>
                          </div>
                          <div
                            className={`dropdown__popup ${isMore ? 'visible' : null
                              } `}>
                            <ul className="space-y-10">
                              <li>
                                <Popup
                                  className="custom"
                                  ref={ref}
                                  trigger={
                                    <Link
                                      to="#"
                                      className="content space-x-10 d-flex">
                                      <i className="ri-flag-line" />
                                      <span> Report </span>
                                    </Link>
                                  }
                                  position="bottom center">
                                  <div>
                                    <div
                                      className="popup"
                                      id="popup_bid"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-hidden="true">
                                      <div>
                                        <div className="space-y-20">
                                          <h5>
                                            Thank you,
                                            <span className="color_green">
                                              we've received your report
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popup>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-white btn-sm" onClick={() =>
                      window.open(
                        `${getExplorer(chainId)}token/${NFTDetails.token_address}?a=${NFTDetails.token_id}`,
                        "_blank"
                      )
                    }>
                    Ver prueba de autenticidad
                  </button>{nftToBuy}

                  <div className="box">
                    <Tabs className="space-y-20">
                      <div style={{ overflowX: "auto" }} className="d-flex justify-content-between mb-30_reset">
                        <TabList className="d-flex space-x-10 mb-30 nav-tabs">
                          <Tab className="nav-item">
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-1"
                              role="tab">
                              Utilidades
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-2"
                              role="tab">
                              Descripcion
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-3"
                              role="tab">
                              Propiedades
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-4"
                              role="tab">
                              Historial
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-5"
                              role="tab">
                              Detalles
                            </Link>
                          </Tab>
                        </TabList>

                      </div>
                      <div className="hr" />
                      <div className="tab-content">
                        {loadingApiHit &&
                          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img src="/img/loader.gif" alt="" />
                          </div>
                        }
                        <TabPanel>
                          {apiHitResponse && Object.entries(apiHitResponse).map((attributesAsTuple) => {
                            //if (utilidades.includes(attributesAsTuple[0]))
                            if ( attributesAsTuple[1].is_membership === true)
                            //if (attributesAsTuple[0] < 12)  
                            return (
                                <><div class="d-flex justify-content-between mb-10">
                                <p>Utilidad</p>
                                {/*} {matchNumberToUtilityName(attributesAsTuple[0])}: {attributesAsTuple[1].quantity - attributesAsTuple[1].used_quantity} {matchNumberToUnit(attributesAsTuple[0])}  */}
                                <p className="d-flex justify-content-end">
                                  {
                                  attributesAsTuple[1].name
                                  //matchNumberToUtilityName(attributesAsTuple[0])
                                }
                                </p>
                              </div>
                              <div class="d-flex justify-content-between mb-10">
                                </div>
                                <div class="d-flex justify-content-between mb-10">
                                </div>
                                </>
                            ) 
                            if ( attributesAsTuple[1].is_membership === false)
                            return (
                                <div class="d-flex justify-content-between mb-10">
                                  <p>Acceso</p>
                                  <p className="d-flex justify-content-end">
                                  {
                                  attributesAsTuple[1].name
                                  //matchNumberToUtilityName(attributesAsTuple[0])
                                  }: {attributesAsTuple[1].quantity - attributesAsTuple[1].used_quantity} {attributesAsTuple[1].uom}
                                   {//matchNumberToUnit(attributesAsTuple[0])
                                   }
                                  </p>
                                </div>
                            ) 
                            return '';
                          })}
                        </TabPanel>
                        <TabPanel className="active">
                          {NFTDetails?.metadata?.description ? (
                            <p className='m-10'>{NFTDetails.metadata.description}</p>
                          ) : (
                            <p>There is no description to show</p>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {NFTDetails?.metadata?.attributes?.map((attribute, index) => (
                            <div className="btn btn-primary btn-md m-10">
                              {attribute.trait_type}: {attribute.value}
                            </div>
                          ))}
                        </TabPanel>
                        <TabPanel>
                          <div className="space-y-20 overflow-auto" style={{ "height": "225px" }}>
                            {(NFTTransfers?.list?.length > 0) && NFTTransfers.list.map((transfer, index) => {
                              return  ( 
                              <div className="creator_item creator_card space-x-10">
                                <div className="avatars space-x-10">
                                  
                                  <div>
                                      {
                                          (() => {
                                            if (transfer.from_address !== '0x0000000000000000000000000000000000000000'){
                                              return (
                                                <p className="color_black">
                                                  <Link to={`/user/${transfer.from_address}`}>
                                                    <img
                                                      src={getProfilePic(transfer.from_address)}
                                                      alt="Avatar"
                                                      className="avatar avatar-sm"
                                                    />
                                                  </Link>
                                                  &nbsp;
                                                  <Link
                                                    className="color_black txt _bold"
                                                    to={`/user/${transfer.from_address}`}>
                                                    {getProfileUsername(transfer.from_address)}
                                                  </Link>
                                                  &nbsp; transfirio el NFT a &nbsp;
                                                    <Link to={`/user/${transfer.to_address}`}>
                                                      <img
                                                        src={getProfilePic(transfer.to_address)}
                                                        alt="Avatar"
                                                        className="avatar avatar-sm"
                                                      />
                                                    </Link>
                                                    &nbsp;
                                                    <Link
                                                      className="color_black txt _bold"
                                                      to={`/user/${transfer.to_address}`}>
                                                      {getProfileUsername(transfer.to_address)}
                                                    </Link>
                                                    {/* 
                                                    &nbsp;a valor de &nbsp;<span className="color_brand">{transfer.amount} USDC </span>
                                                    */}
                                                    &nbsp; el &nbsp;
                                                  <span className="date color_text">
                                                    {formatDate(transfer.block_timestamp)}
                                                  </span>
                                                </p>
                                              )
                                            } else {
                                              return(
                                                <p className="color_black">
                                                  <Link to={`/user/${transfer.to_address}`}>
                                                    <img
                                                      src={getProfilePic(transfer.to_address)}
                                                      alt="Avatar"
                                                      className="avatar avatar-sm"
                                                    />
                                                  </Link>&nbsp;
                                                  <Link
                                                    className="color_black txt _bold"
                                                    to={`/user/${transfer.to_address}`}>
                                                    {getProfileUsername(transfer.to_address)}
                                                  </Link>
                                                  &nbsp;creo el NFT &nbsp;
                                                  {/* 
                                                   a valor de <span className="color_brand">{transfer.amount} USDC </span> 
                                                  */}
                                                  el&nbsp;
                                                  <span className="date color_text">
                                                    {formatDate(transfer.block_timestamp)}
                                                  </span>
                                                </p>
                                              )
                                            }
                                          })()
                                        } 
                                  </div>
                                </div>
                              </div>
                              )}
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between mb-10">
                              <p>
                                Direccion del contrato
                              </p>
                              <a href={`${getExplorer(chainId)}address/${NFTDetails.token_address}`} target="_blank" rel='noreferrer'>
                                {formatAddress(NFTDetails?.token_address)}
                              </a>
                            </div>
                            <div class="d-flex justify-content-between mb-10">
                              <p>
                                ID del token
                              </p>
                              <a href={NFTDetails.token_uri} target="_blank" rel="noreferrer" className="d-flex justify-content-end">
                                {NFTDetails.token_id}
                              </a>
                            </div>
                            <div class="d-flex justify-content-between mb-10">
                              <p>
                                Estandar de token
                              </p>
                              <p className="d-flex justify-content-end">
                                {NFTDetails.contract_type && (NFTDetails.contract_type.slice(0, 3) + "-" + NFTDetails.contract_type.slice(3))}
                              </p>
                            </div>
                            <div class="d-flex justify-content-between mb-10">
                              <p>
                                Blockchain
                              </p>
                              <p className="d-flex justify-content-end">
                                Polygon
                              </p>
                            </div>
                            <div class="d-flex justify-content-between mb-10">
                              <p>
                                DAO fee
                              </p>
                              <p className="d-flex justify-content-end">
                                2.5%
                              </p>
                            </div>
                          </div>
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                  {getRoyalties(NFTDetails) && (
                    <p>Regalias: {getRoyalties(NFTDetails)} %</p>
                  )}
                  <div className="hr2" />
                  <div className="creators">
                    {/* <div className="row">
                      <p>Created by:</p>
                      <div className="col-lg-6 mt-10">
                        <div className="avatars space-x-5">
                          <div className="media">
                            <div className="badge">
                              <img
                                className="badge"
                                src={  "/img/icons/Badge.svg" }
                                alt="ImgPreview"
                              />
                            </div>
                            <Link to={`/user/${NFTTransfers?.creator}`}>
                              <img
                                src={ getProfilePic(NFTTransfers.creator)}
                                alt="Avatar"
                                className="avatar avatar-sm"
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to={`/user/${NFTTransfers?.creator}`}>
                              <p className="avatars_name color_black">
                                { getProfileUsername(NFTTransfers?.creator)}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                    {/*ACA estaba el bloque de precio y boton de compra */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={`Buy ${NFTDetails?.metadata?.name} #${NFTDetails?.token_id}`}
          visible={visible}
          onCancel={() => setVisibility(false)}
          footer={[
            /*<button className="btn btn-primary btn-sm btn-block" onClick={approveUSDC}>Aprobar USDC</button>*/
            <button className="btn btn-primary btn-sm btn-block ml-10" onClick={purchase}>Comprar</button>
          ]}
        >
          <Spin spinning={loading}>
            <div
              style={{
                width: "250px",
                margin: "auto",
              }}
            >
              <Badge.Ribbon
                color="green"
                text={`${getMarketItem()?.price / ("1e" + 6)
                  } USDC`}
              >
                {NFTDetails?.animation_url ? (
                  <video
                    //autoPlay
                    controls
                    type="video/mp4"
                    className="item_img"
                    src={NFTDetails?.animation_url}
                    alt="ImgPreview"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                ) : (
                  <img
                    className="item_img"
                    src={NFTDetails?.image ? NFTDetails.image : "/img/bg/no-img.png"}
                    alt="ImagePreview"
                    style={{
                      width: "250px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                )}

              </Badge.Ribbon>
            </div>
          </Spin>
        </Modal>









        <ToastContainer position="bottom-right" />
        <Footer />
      </div>
    );
  } else {
    return <Link></Link>
  }
};

export default ItemDetails;